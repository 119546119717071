import { useEffect, useRef } from "react";
import gsap from "gsap";
import classes from "./Market.module.scss";
import img1 from "../../../assets/img/Academy/5/1.png";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Market = () => {
  const { t } = useTranslation();
  const imageRef = useRef(null);
  const bodyRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(
        imageRef.current,
        {
          y: 200,
        },
        {
          y: 0,
          scrollTrigger: {
            trigger: bodyRef.current,
            end: "+=400px",
            scrub: 3,
          },
        }
      );
    }
  }, []);
  return (
    <div className={[classes.market, "mt"].join(" ")}>
      <div className={classes.marketBody} ref={bodyRef}>
        <div className="container">
          <div className={classes.marketRow}>
            <div className={classes.marketRowLeft} ref={imageRef}>
              <img src={img1} alt="" />
            </div>
            <div className={classes.marketRowRight}>
              <h3 className={[classes.marketRowRightTitle, "font-35"].join(" ")}>
                {t('academy_market_title')}
              </h3>
              <ul className={classes.marketRowRightList}>
                <li className={[classes.marketRowRightListItem, "font-17"].join(" ")}>
                  {t('academy_market_li_01')}
                </li>
                <li className={[classes.marketRowRightListItem, "font-17"].join(" ")}>
                  {t('academy_market_li_02')}
                </li>
                <li className={[classes.marketRowRightListItem, "font-17"].join(" ")}>
                  {t('academy_market_li_03')}
                </li>
                <li className={[classes.marketRowRightListItem, "font-17"].join(" ")}>
                  {t('academy_market_li_04')}
                </li>
              </ul>
              <p className={[classes.marketRowRightText, "font-17"].join(" ")}>
                {t('academy_market_text')}
              </p>
              <div className={classes.marketRowRightBtn}>
                <Button>
                  {t('btn_registration')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;

import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import classes from "./Banner.module.scss";
import img1 from "../../../assets/img/Trading/1/1.png";
import img2 from "../../../assets/img/Trading/1/2.png";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  gsap.registerPlugin(ScrollTrigger);

  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const monetRef1 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        y: 0,
        x: 0,
        autoAlpha: 1,
        delay: 0.3,
        duration: 1,
      });
    }
  }, [width]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={classes.banner}>
      <div className={classes.bannerBody}>
        <div className={classes.bannerImg}>
          <img src={img2} alt="Diverse set of financial instruments" />
        </div>
        <div className="container">
          <div className={classes.bannerRow}>
            <div className={classes.bannerRowLeft}>
              <h2 className={[classes.bannerRowLeftTitle, "font-35"].join(" ")}>
                {t('assets_banner_title')}
              </h2>
              <p className={[classes.bannerRowLeftText, "font-17"].join(" ")}>
                {t('assets_banner_text_01')}
              </p>
              <p className={[classes.bannerRowLeftText, "font-17"].join(" ")}>
                {t('assets_banner_text_02')}
              </p>
              <p className={[classes.bannerRowLeftText, "font-17"].join(" ")}>
                {t('assets_banner_text_03')}
              </p>
              <p className={[classes.bannerRowLeftText, "font-17"].join(" ")}>
                {t('assets_banner_text_04')}
              </p>
              <div className={classes.bannerRowLeftBtn}>
                <Button>
                  {t('btn_start_trading')}
                </Button>
              </div>
            </div>
            <div
              className={classes.bannerRowRight}
              ref={monetRef1}
            >
              <img src={img1} alt="Diverse set of financial instruments" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;

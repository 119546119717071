import React, { useEffect, useRef } from "react";
import classes from './Tools.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import circle from '../../../assets/img/HomePage/tools_02.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Tools = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const bodyRef = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            title: 'home_tools_card_01_title',
            text: 'home_tools_card_01_text',
        },
        {
            ref: cardRef_02,
            title: 'home_tools_card_02_title',
            text: 'home_tools_card_02_text',
        },
        {
            ref: cardRef_03,
            title: 'home_tools_card_03_title',
            text: 'home_tools_card_03_text',
        },
        {
            ref: cardRef_04,
            title: 'home_tools_card_04_title',
            text: 'home_tools_card_04_text',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=100px bottom",
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 150,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=100px bottom",
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 150,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=200px bottom",
                    end: "+=500px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_04.current, {
                y: 250,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=200px bottom",
                    end: "+=500px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section ref={bodyRef} className={classes.toolsWrap}>
            <div className="container">
                <div className={classes.tools}>
                    <img className={classes.circle} src={circle} alt=''/>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-35'].join(' ')}>
                            {t('home_tools_title')}
                        </h2>
                        <div className={[classes.text, 'font-17'].join(' ')}>
                            <p>
                                {t('home_tools_text_01')}
                            </p>
                            <p>
                                {t('home_tools_text_02')}
                            </p>
                            <p>
                                {t('home_tools_text_03')}
                            </p>
                        </div>
                        <div className={classes.btn}>
                            <Button href="tools" white>
                                {t('btn_learn_more')}
                            </Button>
                        </div>
                    </div>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div ref={card.ref} key={index} className={classes.cardWrap}>
                                <div className={classes.card}>
                                    <h4 className={classes.cardTitle}>
                                        {t(card.title)}
                                    </h4>
                                    <p className={classes.cardText}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.btnMob}>
                        <Button href="tools" white>
                            {t('btn_learn_more')}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tools;

import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/logo.png";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  return (
    <footer className="mt container">
      <div className={classes.footer}>
        <div className={classes.logo}>
          <img src={logo} alt="" />
        </div>
        <p className={classes.mission}>
          <Trans>
            {t('footer_mission')}
          </Trans>
        </p>
        <div className={classes.block}>
          <p>
            <b>
              {t('footer_text')}
            </b>
          </p>
          <p>
            {t('footer_countries')}
          </p>
          <Link to={`/${lang}/legal-documents`} className={classes.docs}>
            {t('header_legal_docs')}
          </Link>
        </div>
        <div className={`${classes.block} ${classes.bottom}`}>
          <p className={classes.rights}>
            {t('footer_rights')}
          </p>
          <p className={classes.rsk}>
            <Trans>
              {t('footer_risk')}
            </Trans>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import classes from './HowToConnect.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";

const HowToConnect = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.howToConnect}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    <Trans>
                        {t('view_connect_title')}
                    </Trans>
                </h2>
                <div className={classes.content}>
                    <div className={classes.col}>
                        <ul className="font-17">
                            <li>
                                {t('view_connect_li_01')}
                            </li>
                            <li>
                                {t('view_connect_li_02')}
                            </li>
                            <li>
                                {t('view_connect_li_03')}
                            </li>
                        </ul>
                    </div>
                    <div className={classes.col}>
                        <p className={[classes.text, 'font-17'].join(' ')}>
                            {t('view_connect_text')}
                        </p>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn_start_trading')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowToConnect;

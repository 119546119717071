import React, { useState } from "react";
import classes from "./Tabs.module.scss";
import { useTranslation } from "react-i18next";

const Tabs = () => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([
    {
      id: 1,
      btnText: "assets_tabs_forex",
      active: true,
      texts: [
        ["assets_tabs_fx_trading_asset"],
        ["EUR/USD"],
        ["USD/JPY"],
        ["GBP/USD"],
        ["GBP/EUR"],
      ],
    },
    {
      id: 2,
      btnText: "assets_tabs_metals",
      active: false,
      texts: [
        ["assets_tabs_symbol", "assets_tabs_description"],
        ["Palladium", "Palladium"],
        ["Platinum", "Platinum"],
        ["XAGEUR", "Silver/Euro"],
        ["XAGUSD", "Silver/US Dollar"],
        ["XAUEUR", "Gold/Euro"],
        ["XAUUSD", "Gold/US Dollar"],
      ],
    },
    {
      id: 3,
      btnText: "assets_tabs_energies",
      active: false,
      texts: [
        ["assets_tabs_symbol", "assets_tabs_description"],
        ["UKOIL.S", "UK Brent Oil"],
        ["USOIL.S", "US Crude Oil"],
        ["-", "-"],
        ["-", "-"],
        ["-", "-"],
        ["-", "-"],
      ],
    },
    {
      id: 4,
      btnText: "assets_tabs_stock_market",
      active: false,
      texts: [
        ["assets_tabs_symbol", "assets_tabs_description"],
        ["AMEX", "EURONEXT — Adidas Salomon"],
        ["SPX", "Standard & Poor 500"],
        ["Adidas.K", "EURONEXT — Adidas Salomon"],
        ["AMEX", "EURONEXT — Adidas Salomon"],
        ["SPX", "Standard & Poor 500"],
        ["Adidas.K", "EURONEXT — Adidas Salomon"],
      ],
    },
    {
      id: 5,
      btnText: "assets_tabs_indices",
      active: false,
      texts: [
        ["assets_tabs_symbol", "assets_tabs_description"],
        ["EU50.F", "EU STOXX50"],
        ["FRA40.F", "France 40"],
        ["GER40.F", "Germany 40"],
        ["US500.F", "US SPX500"],
        ["US100.F", "US Tech 100"],
        ["100.F", "UK 100"],
      ],
    },
    {
      id: 6,
      btnText: "assets_tabs_cryptocurrencies",
      active: false,
      texts: [
        ["assets_tabs_symbol", "assets_tabs_description"],
        ["BTC/USD", "Bitcoin USD"],
        ["BTC/USDT", "Bitcoin stablecoin USDT"],
        ["ETH/USD", "Ethereum USD"],
        ["ETH/USDT", "Ethereum stablecoin USDT"],
        ["BTC/ETH", "Bitcoin Ethereum"],
        ["DOGE/USDT", "DOGE coin stablecoin USDT"],
      ],
    },
  ]);
  return (
    <div className={[classes.tabs, "mt"].join(" ")}>
      <div className={classes.tabsBody}>
        <div className="container">
          <div className={classes.tabsContent}>
            <div className={classes.tabsContentButtons}>
              {tabs.map((btnItem, index) => {
                return (
                  <div
                    key={index}
                    className={btnItem.active
                      ? [classes.tabsContentBtn, classes.active,].join(" ")
                      : classes.tabsContentBtn
                    }
                    onClick={() => {
                      setTabs(
                        tabs.map((subI) => {
                          if (btnItem.id === subI.id) {
                            return { ...subI, active: true };
                          } else {
                            return { ...subI, active: false };
                          }
                        })
                      );
                    }}
                  >
                    {t(btnItem.btnText)}
                  </div>
                );
              })}
            </div>
            <div className={classes.tabsContentRight}>
              {tabs.map((tableItem, index) => {
                return (
                  <div
                    key={index}
                    className={tableItem.active
                      ? [classes.tabsContentTable, classes.active].join(" ")
                      : classes.tabsContentTable
                    }
                  >
                    {tableItem.texts.map((textItem, indexI) => {
                      return (
                        <div key={indexI} className={classes.tabsContentTableRow}>
                          {textItem.map((subItem, indexL) => {
                            return (
                              <p
                                key={indexL}
                                className={tableItem.id === 1
                                  ? [classes.tabsContentTableRowColumn, classes.centerText].join(" ")
                                  : classes.tabsContentTableRowColumn
                                }
                              >
                                {t(subItem)}
                              </p>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;

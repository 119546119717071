import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/TradingComponents/Banner/Banner";
import Tabs from "../../components/TradingComponents/Tabs/Tabs";
import Efficient from "../../components/TradingComponents/Efficient/Efficient";

const TradingAssets = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Trading Assets</title>
        <meta
          name="title"
          content="iMarkets Trade provides a diverse set of trading assets as well as low fees for efficient and profitable trading."
        />
        <meta
          property="og:title"
          content="iMarkets Trade provides a diverse set of trading assets as well as low fees for efficient and profitable trading."
        />
        <meta
          property="twitter:title"
          content="iMarkets Trade provides a diverse set of trading assets as well as low fees for efficient and profitable trading."
        />

        <meta
          name="description"
          content="iMarkets Trade's diverse trading assets can help you diversify your trading portfolio. Begin exploring the world of trading opportunities with iMarkets Trade today and expand your investment horizons."
        />
        <meta
          property="og:description"
          content="iMarkets Trade's diverse trading assets can help you diversify your trading portfolio. Begin exploring the world of trading opportunities with iMarkets Trade today and expand your investment horizons."
        />
        <meta
          property="twitter:description"
          content="iMarkets Trade's diverse trading assets can help you diversify your trading portfolio. Begin exploring the world of trading opportunities with iMarkets Trade today and expand your investment horizons."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Tabs />
        <Efficient />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default TradingAssets;

import React, { useEffect, useRef } from "react";
import classes from './UnderBanner.module.scss';
import line_01 from '../../../assets/img/HomePage/Underbanner/line_01.jpg';
import line_02 from '../../../assets/img/HomePage/Underbanner/line_02.jpg';
import line_03 from '../../../assets/img/HomePage/Underbanner/line_03.jpg';
import line_04 from '../../../assets/img/HomePage/Underbanner/line_04.jpg';
import line_05 from '../../../assets/img/HomePage/Underbanner/line_05.jpg';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const UnderBanner = () => {
    const lineWrapRef_01 = useRef(null);
    const lineWrapRef_02 = useRef(null);
    const lineWrapRef_03 = useRef(null);
    const lineWrapRef_04 = useRef(null);
    const lineWrapRef_05 = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().to(lineWrapRef_01.current, {
                x: -280,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "bottom bottom",
                    end: "bottom top",
                    scrub: 4,
                },
            });
            gsap.timeline().to(lineWrapRef_02.current, {
                x: 150,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "bottom bottom",
                    end: "bottom top",
                    scrub: 4,
                },
            });
            gsap.timeline().to(lineWrapRef_03.current, {
                x: -400,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "bottom bottom",
                    end: "bottom top",
                    scrub: 4,
                },
            });
            gsap.timeline().to(lineWrapRef_04.current, {
                x: -500,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "bottom bottom",
                    end: "bottom top",
                    scrub: 4,
                },
            });
            gsap.timeline().to(lineWrapRef_05.current, {
                x: -550,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "bottom bottom",
                    end: "bottom top",
                    scrub: 4,
                },
            });
        }
    }, []);
    return (
        <section className={classes.underBanner}>
            <div className={classes.bgWrap}>
                <div ref={lineWrapRef_01} className={[classes.lineWrap_01, classes.lineWrap].join(' ')}>
                    <img className={classes.line} src={line_01} alt=''/>
                </div>
                <div ref={lineWrapRef_02} className={[classes.lineWrap_02, classes.lineWrap].join(' ')}>
                    <img className={classes.line} src={line_02} alt=''/>
                </div>
                <div ref={lineWrapRef_03} className={[classes.lineWrap_03, classes.lineWrap].join(' ')}>
                    <img className={classes.line} src={line_03} alt=''/>
                </div>
                <div ref={lineWrapRef_04} className={[classes.lineWrap_04, classes.lineWrap].join(' ')}>
                    <img className={classes.line} src={line_04} alt=''/>
                </div>
                <div ref={lineWrapRef_05} className={[classes.lineWrap_05, classes.lineWrap].join(' ')}>
                    <img className={classes.line} src={line_05} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;

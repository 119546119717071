import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Welcome from "../../components/AcademyComponents/Welcome/Welcome";
import Exceptional from "../../components/AcademyComponents/Exceptional/Exceptional";
import Practice from "../../components/AcademyComponents/Practice/Practice";
import Support from "../../components/AcademyComponents/Support/Support";
import Bonus from "../../components/AcademyComponents/Bonus/Bonus";
import Market from "../../components/AcademyComponents/Market/Market";

const Academy = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Academy</title>
        <meta
          name="title"
          content="Explore the iMarkets Trade Trading Academy to Unlock Your Trading Potential"
        />
        <meta
          property="og:title"
          content="Explore the iMarkets Trade Trading Academy to Unlock Your Trading Potential"
        />
        <meta
          property="twitter:title"
          content="Explore the iMarkets Trade Trading Academy to Unlock Your Trading Potential"
        />

        <meta
          name="description"
          content="The iMarkets Trade Trading Academy is an all-encompassing educational platform that offers a variety of courses, expert insights, and practical resources to help you learn effective trading strategies, gain market insights, and improve your trading performance."
        />
        <meta
          property="og:description"
          content="The iMarkets Trade Trading Academy is an all-encompassing educational platform that offers a variety of courses, expert insights, and practical resources to help you learn effective trading strategies, gain market insights, and improve your trading performance."
        />
        <meta
          property="twitter:description"
          content="The iMarkets Trade Trading Academy is an all-encompassing educational platform that offers a variety of courses, expert insights, and practical resources to help you learn effective trading strategies, gain market insights, and improve your trading performance."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Welcome />
        <Exceptional />
        <Practice />
        <Support />
        <Bonus />
        <Market />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default Academy;

import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import classes from "./Banner.module.scss";
import img1 from "../../../assets/img/Tools/1/1.png";
import img2 from "../../../assets/img/Tools/1/2.png";
import img3 from "../../../assets/img/Tools/1/3.png";
import img4 from "../../../assets/img/Tools/1/4.png";
import img5 from "../../../assets/img/Tools/1/5.png";
import img6 from "../../../assets/img/Tools/1/6.png";
import imgMob1 from "../../../assets/img/Tools/1/mob1.png";
import imgMob2 from "../../../assets/img/Tools/1/mob2.png";
import imgBg from "../../../assets/img/Tools/1/bg.png";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  gsap.registerPlugin(ScrollTrigger);

  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);
  const monetRef3 = useRef(null);
  const monetRef4 = useRef(null);
  const monetRef5 = useRef(null);
  const monetRef6 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        y: 0,
        x: 0,
        rotate: 0,
        delay: 0.3,
        duration: 1,
      });
      gsap.timeline().to(monetRef2.current, {
        y: 0,
        x: 0,
        rotate: 0,
        delay: 0.3,
        duration: 1,
      });
      gsap.timeline().to(monetRef3.current, {
        y: 0,
        x: 0,
        rotate: 0,
        delay: 0.3,
        duration: 1,
      });
      gsap.timeline().to(monetRef4.current, {
        y: 0,
        x: 0,
        rotate: 0,
        delay: 0.3,
        duration: 1,
      });
      gsap.timeline().to(monetRef5.current, {
        y: 0,
        x: 0,
        rotate: 0,
        delay: 0.3,
        duration: 1,
      });
      gsap.timeline().to(monetRef6.current, {
        y: 0,
        x: 0,
        rotate: 0,
        delay: 0.3,
        duration: 1,
      });
    }
  }, [width]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.banner, ""].join(" ")}>
      <div className={[classes.bannerBody, ""].join(" ")}>
        <div className={[classes.bannerBg, ""].join(" ")}>
          <img src={imgBg} alt="Become a successful trader" />
        </div>
        <div className="container">
          <div className={[classes.bannerRow, ""].join(" ")}>
            <div className={[classes.bannerRowLeft, ""].join(" ")}>
              <h2 className={[classes.bannerRowLeftTitle, "font-35"].join(" ")}>
                {t('tools_banner_title')}
              </h2>
              <p className={[classes.bannerRowLeftText, "font-17"].join(" ")}>
                {t('tools_banner_text_01')}
              </p>
              <p className={[classes.bannerRowLeftText, "font-17"].join(" ")}>
                {t('tools_banner_text_02')}
              </p>
              <div className={[classes.bannerRowLeftBtn, ""].join(" ")}>
                <Button>
                  {t('btn_start_trading')}
                </Button>
              </div>
            </div>
            <div className={[classes.bannerRowRight, ""].join(" ")}>
              <div className={[classes.bannerRowRightImgs, ""].join(" ")}>
                <div
                  className={[classes.bannerRowRightImgsImg1, ""].join(" ")}
                  ref={monetRef1}
                >
                  <img src={img1} alt="Become a successful trader" />
                </div>
                <div
                  className={[classes.bannerRowRightImgsImg2, ""].join(" ")}
                  ref={monetRef2}
                >
                  <img src={img2} alt="Become a successful trader" />
                </div>
                <div
                  className={[classes.bannerRowRightImgsImg3, ""].join(" ")}
                  ref={monetRef3}
                >
                  <img src={img3} alt="Become a successful trader" />
                </div>
                <div
                  className={[classes.bannerRowRightImgsImg4, ""].join(" ")}
                  ref={monetRef4}
                >
                  <img src={img4} alt="Become a successful trader" />
                </div>
                <div
                  className={[classes.bannerRowRightImgsImg5, ""].join(" ")}
                  ref={monetRef5}
                >
                  <img src={img5} alt="Become a successful trader" />
                </div>
                <div
                  className={[classes.bannerRowRightImgsImg6, ""].join(" ")}
                  ref={monetRef6}
                >
                  <img src={img6} alt="Become a successful trader" />
                </div>
              </div>
            </div>
          </div>
          <div className={[classes.bannerMob1, ""].join(" ")}>
            <img src={imgMob1} alt="Become a successful trader" />
          </div>
          <div className={[classes.bannerMob2, ""].join(" ")}>
            <img src={imgMob2} alt="Become a successful trader" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;

import React from "react";
import classes from './BecomeTrader.module.scss';
import { Trans, useTranslation } from "react-i18next";

const steps = [
    {
        title: 'home_become_card_01_title',
        text: 'home_become_card_01_text',
    },
    {
        title: 'home_become_card_02_title',
        text: 'home_become_card_02_text',
    },
    {
        title: 'home_become_card_03_title',
        text: 'home_become_card_03_text',
    },
];

const BecomeTrader = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.becomeTrader}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t('home_become_title')}
                </h2>
                <div className={classes.steps}>
                    {steps.map((step, index) =>
                        <div key={index} className={classes.step}>
                            <div className={classes.stepNum}>
                                <span>
                                    {index + 1}
                                </span>
                            </div>
                            <h4 className={classes.stepTitle}>
                                {t(step.title)}
                            </h4>
                            <p className={[classes.stepText, 'font-17'].join(' ')}>
                                <Trans>
                                    {t(step.text)}
                                </Trans>
                            </p>
                        </div>
                    )}
                    <div className={classes.line}></div>
                </div>
            </div>
        </section>
    );
};

export default BecomeTrader;

import React from "react";
import classes from './Benefits.module.scss';
import { Trans, useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/HomePage/Benefits/icon_01.png';
import icon_02 from '../../../assets/img/HomePage/Benefits/icon_02.png';
import icon_03 from '../../../assets/img/HomePage/Benefits/icon_03.png';
import icon_04 from '../../../assets/img/HomePage/Benefits/icon_04.png';
import icon_05 from '../../../assets/img/HomePage/Benefits/icon_05.png';
import icon_06 from '../../../assets/img/HomePage/Benefits/icon_06.png';
import Button from "../../GlobalComponents/Button/Button";

const cards = [
    {
        icon: icon_01,
        text: 'home_benefits_li_01',
    },
    {
        icon: icon_02,
        text: 'home_benefits_li_02',
    },
    {
        icon: icon_03,
        text: 'home_benefits_li_03',
    },
    {
        icon: icon_04,
        text: 'home_benefits_li_04',
    },
    {
        icon: icon_05,
        text: 'home_benefits_li_05',
    },
    {
        icon: icon_06,
        text: 'home_benefits_li_06',
    },
];

const Benefits = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.benefits}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t('home_benefits_title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img className={classes.cardIcon} src={card.icon} alt=''/>
                            <p className={[classes.cardText, 'font-17'].join(' ')}>
                                <Trans>
                                    {t(card.text)}
                                </Trans>
                            </p>
                        </div>
                    )}
                </div>
                <div className={classes.btn}>
                    <Button>
                        {t('btn_start_trading')}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default Benefits;

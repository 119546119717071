import React, { useEffect, useRef } from "react";
import classes from './TradingAcademy.module.scss';
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const cards = [
    {
        title: 'home_academy_card_01_title',
        text: 'home_academy_card_01_text',
    },
    {
        title: 'home_academy_card_02_title',
        text: 'home_academy_card_02_text',
    },
    {
        title: 'home_academy_card_04_title',
        text: 'home_academy_card_04_text',
    },
    {
        title: 'home_academy_card_03_title',
        text: 'home_academy_card_03_text',
    },
    {
        title: 'home_academy_card_05_title',
        text: 'home_academy_card_05_text',
    },
];

const TradingAcademy = () => {
    const { t } =  useTranslation();
    const lang = Cookies.get('i18next');
    const bodyRef = useRef(null);
    const planeWrapRef = useRef(null);
    const planeRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(planeWrapRef.current, {
                y: 30,
                x: 100,
            }, {
                y: 0,
                x: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "center bottom",
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().to(planeRef.current, {
                y: -40,
                x: -150,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top top",
                    end: "+=200px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.tradingAcademy}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t('home_academy_title')}
                </h2>               
                <p className={[classes.text, 'font-17'].join(' ')}>
                    {t('home_academy_text')}
                </p>
                <div ref={bodyRef} className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <h4 className={classes.cardTitle}>
                                {t(card.title)}
                            </h4>
                            <p className={classes.cardText}>
                                {t(card.text)}
                            </p>
                            {index === 0 &&
                                <div ref={planeWrapRef} className={classes.planeWrap}>
                                    <div ref={planeRef} className={classes.plane}></div>
                                </div>
                            }
                            {index === 4 &&
                                <a 
                                    href={`https://user.imarketstrade.xyz/signup?lang=${lang}`}
                                    className={classes.btn}
                                >
                                </a>
                            }
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default TradingAcademy;

import logo from '../assets/img/logo.png';
import img1 from '../assets/img/Academy/1/1.png';
import img2 from '../assets/img/HomePage/Banner/icon_01.png';
import img3 from '../assets/img/HomePage/Banner/icon_02.png';
import img4 from '../assets/img/HomePage/Banner/icon_03.png';
import img5 from '../assets/img/HomePage/Banner/icon_04.png';
import img6 from '../assets/img/HomePage/Banner/icon_05.png';
import img7 from '../assets/img/HomePage/Banner/plane.png';
import img8 from '../assets/img/Tools/1/1.png';
import img9 from '../assets/img/Tools/1/2.png';
import img10 from '../assets/img/Tools/1/3.png';
import img11 from '../assets/img/Tools/1/4.png';
import img12 from '../assets/img/Tools/1/5.png';
import img13 from '../assets/img/Tools/1/6.png';
import img14 from '../assets/img/Tools/1/bg.png';
import img15 from '../assets/img/Tools/1/mob1.png';
import img16 from '../assets/img/Tools/1/mob2.png';
import img17 from '../assets/img/Trading/1/1.png';
import img18 from '../assets/img/Trading/1/2.png';
import img19 from '../assets/img/Trading/1/mob.png';
import img20 from '../assets/img/TradingView/banner.png';


export const images = [
    logo,
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20,
];

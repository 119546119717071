import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import classes from "./Welcome.module.scss";
import img1 from "../../../assets/img/Academy/1/1.png";
import Button from "../../GlobalComponents/Button/Button";
import { Trans, useTranslation } from "react-i18next";

const Welcome = () => {
  const { t } = useTranslation();
  gsap.registerPlugin(ScrollTrigger);
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const monetRef1 = useRef(null);
  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        y: 0,
        x: 0,
        rotate: 0,
        delay: 0.3,
        autoAlpha: 1,
        duration: 1,
      });
    }
  }, [width]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={classes.welcome}>
      <div className={classes.welcomeBody}>
        <div className="container">
          <div className={classes.welcomeRow}>
            <div className={classes.welcomeRowLeft}>
              <h2
                className={[classes.welcomeRowLeftTitle, "font-35"].join(" ")}
              >
                <Trans>
                  {t('academy_banner_title')}
                </Trans>
              </h2>
              <p className={[classes.welcomeRowLeftText, "font-17"].join(" ")}>
                {t('academy_banner_text')}
              </p>
              <div className={classes.welcomeRowLeftBtn}>
                <Button>
                  {t('btn_start_trading')}
                </Button>
              </div>
            </div>
            <div
              className={classes.welcomeRowRight}
              ref={monetRef1}
            >
              <img
                src={img1}
                alt="Welcome to the iMarkets Trade Trading Academy!"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;

import React from "react";
import classes from "./Bonus.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Bonus = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.bonus, "mt"].join(" ")}>
      <div className={[classes.bonusBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.bonusTitle, "font-35"].join(" ")}>
            {t('academy_bonus_title')}
          </h2>

          <div className={[classes.bonusRow, ""].join(" ")}>
            <div className={[classes.bonusRowLeft, ""].join(" ")}>
              <p className={[classes.bonusRowLeftText, "font-17"].join(" ")}>
                {t('academy_bonus_text_01')}
              </p>
            </div>
            <div className={[classes.bonusRowRight, ""].join(" ")}>
              <p className={[classes.bonusRowRightText, "font-17"].join(" ")}>
                {t('academy_bonus_text_02')}
              </p>
            </div>
          </div>
          <div className={[classes.bonusBtn, ""].join(" ")}>
            <Button>
              {t('btn_start_trading')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bonus;

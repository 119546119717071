import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import icon_01 from '../../../assets/img/HomePage/Banner/icon_01.png';
import icon_02 from '../../../assets/img/HomePage/Banner/icon_02.png';
import icon_03 from '../../../assets/img/HomePage/Banner/icon_03.png';
import icon_04 from '../../../assets/img/HomePage/Banner/icon_04.png';
import icon_05 from '../../../assets/img/HomePage/Banner/icon_05.png';
import plane from '../../../assets/img/HomePage/Banner/plane.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const lineWrapRef_01 = useRef(null);
    const lineWrapRef_02 = useRef(null);
    const lineWrapRef_03 = useRef(null);
    const lineRef_01 = useRef(null);
    const lineRef_02 = useRef(null);
    const lineRef_03 = useRef(null);
    const planeWrapRef = useRef(null);
    const planeRef = useRef(null);
    const iconWrapRef_01 = useRef(null);
    const iconWrapRef_02 = useRef(null);
    const iconWrapRef_03 = useRef(null);
    const iconWrapRef_04 = useRef(null);
    const iconWrapRef_05 = useRef(null);
    const iconRef_01 = useRef(null);
    const iconRef_02 = useRef(null);
    const iconRef_03 = useRef(null);
    const iconRef_04 = useRef(null);
    const iconRef_05 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(lineWrapRef_01.current, {
                y: 150,
            }, {
                y: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(lineWrapRef_02.current, {
                y: 50,
            }, {
                y: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(lineWrapRef_03.current, {
                y: -100,
            }, {
                y: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(planeWrapRef.current, {
                y: 250,
            }, {
                y: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(iconWrapRef_01.current, {
                y: 80,
                x: -40,
            }, {
                y: 0,
                x: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(iconWrapRef_02.current, {
                y: 60,
                x: -20,
                rotate: 10,
            }, {
                y: 0,
                x: 0,
                rotate: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(iconWrapRef_03.current, {
                y: 20,
                x: -10,
            }, {
                y: 0,
                x: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(iconWrapRef_04.current, {
                y: 80,
                x: 40,
            }, {
                y: 0,
                x: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(iconWrapRef_05.current, {
                y: 20,
                x: 70,
                rotate: -20,
            }, {
                y: 0,
                x: 0,
                rotate: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.timeline().to(planeRef.current, {
                y: -140,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=50px top",
                    end: "+=500px",
                    scrub: 3,
                },
            });
            gsap.timeline().to(lineRef_01.current, {
                y: -300,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=50px top",
                    end: "+=500px",
                    scrub: 3,
                },
            });
            gsap.timeline().to(lineRef_02.current, {
                y: -60,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=50px top",
                    end: "+=500px",
                    scrub: 3,
                },
            });
            gsap.timeline().to(lineRef_03.current, {
                y: 200,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=50px top",
                    end: "+=500px",
                    scrub: 3,
                },
            });
            gsap.timeline().to(iconRef_01.current, {
                y: 50,
                x: -40,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=50px top",
                    end: "+=500px",
                    scrub: 3,
                },
            });
            gsap.timeline().to(iconRef_02.current, {
                y: -50,
                x: -20,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=50px top",
                    end: "+=500px",
                    scrub: 3,
                },
            });
            gsap.timeline().to(iconRef_03.current, {
                y: -90,
                x: -10,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=50px top",
                    end: "+=500px",
                    scrub: 3,
                },
            });
            gsap.timeline().to(iconRef_04.current, {
                y: -20,
                x: 50,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=50px top",
                    end: "+=500px",
                    scrub: 3,
                },
            });
            gsap.timeline().to(iconRef_05.current, {
                y: 50,
                x: 40,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=50px top",
                    end: "+=500px",
                    scrub: 3,
                },
            });
        }
    }, []);
    return (
        <section className="container">
            <div ref={bodyRef} className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={[classes.title, 'font-35'].join(' ')}>
                        {t('home_banner_title')}
                    </h1>
                    <div className={[classes.text, 'font-17'].join(' ')}>
                        <p>
                            {t('home_banner_text_01')}
                        </p>
                        <p>
                            {t('home_banner_text_02')}
                        </p>
                        <p>
                            {t('home_banner_text_03')}
                        </p>
                        <p>
                            {t('home_banner_text_04')}
                        </p>
                        <p>
                            {t('home_banner_text_05')}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn_start_trading')}
                        </Button>
                    </div>
                </div>
                <div className={classes.images}>
                    <div ref={lineWrapRef_01} className={classes.lineWrap_01}>
                        <div ref={lineRef_01} className={classes.line}></div>
                    </div>
                    <div ref={lineWrapRef_02} className={classes.lineWrap_02}>
                        <div ref={lineRef_02} className={classes.line}></div>
                    </div>
                    <div ref={lineWrapRef_03} className={classes.lineWrap_03}>
                        <div ref={lineRef_03} className={classes.line}></div>
                    </div>
                    <div ref={planeRef} className={classes.planeWrap}>
                        <img ref={planeWrapRef} className={classes.plane} src={plane} alt=''/>
                    </div>
                    <div ref={iconWrapRef_01} className={[classes.iconWrap_01, classes.iconWrap].join(' ')}>
                        <img ref={iconRef_01} className={classes.icon} src={icon_01} alt=''/>
                    </div>
                    <div ref={iconWrapRef_02} className={[classes.iconWrap_02, classes.iconWrap].join(' ')}>
                        <img ref={iconRef_02} className={classes.icon} src={icon_02} alt=''/>
                    </div>
                    <div ref={iconWrapRef_03} className={[classes.iconWrap_03, classes.iconWrap].join(' ')}>
                        <img ref={iconRef_03} className={classes.icon} src={icon_03} alt=''/>
                    </div>
                    <div ref={iconWrapRef_04} className={[classes.iconWrap_04, classes.iconWrap].join(' ')}>
                        <img ref={iconRef_04} className={classes.icon} src={icon_04} alt=''/>
                    </div>
                    <div ref={iconWrapRef_05} className={[classes.iconWrap_05, classes.iconWrap].join(' ')}>
                        <img ref={iconRef_05} className={classes.icon} src={icon_05} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;

import { useEffect, useRef } from "react";
import gsap from "gsap";
import classes from "./Practice.module.scss";
import img1 from "../../../assets/img/Academy/3/1.png";
import { useTranslation } from "react-i18next";

const Practice = () => {
  const { t } = useTranslation();
  const imageRef = useRef(null);
  const bodyRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(
        imageRef.current,
        {
          y: 200,
        },
        {
          y: 0,
          scrollTrigger: {
            trigger: bodyRef.current,
            end: "+=400px",
            scrub: 3,
          },
        }
      );
    }
  }, []);
  return (
    <div className={[classes.practice, "mt mb"].join(" ")}>
      <div className={classes.practiceBody} ref={bodyRef}>
        <div className="container">
          <div className={classes.practiceRow}>
            <div className={classes.practiceRowLeft}>
              <h2 className={[classes.practiceRowLeftTitle, "font-35"].join(" ")}>
                {t('academy_practice_title')}
              </h2>
              <p className={[classes.practiceRowLeftText, "font-17"].join(" ")}>
                {t('academy_practice_text')}
              </p>
            </div>
            <div className={classes.practiceRowRight} ref={imageRef}>
              <img src={img1} alt="Practice with Demo Accounts:" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Practice;

import React from "react";
import classes from "./Efficient.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/Trading/2/1.png";
import img2 from "../../../assets/img/Trading/2/2.png";
import img3 from "../../../assets/img/Trading/2/3.png";
import img4 from "../../../assets/img/Trading/2/4.png";
import imgMob from "../../../assets/img/Trading/2/mob.png";
import { useTranslation } from "react-i18next";

const Efficient = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.block, "mt"].join(" ")}>
      <div className={classes.blockBody}>
        <div className="container">
          <div className={classes.blockRow}>
            <div className={classes.blockRowLeft}>
              <h2 className={[classes.blockRowLeftTitle, "font-35"].join(" ")}>
                {t('assets_efficient_title')}
              </h2>
              <p className={[classes.blockRowLeftText, "font-17"].join(" ")}>
                {t('assets_efficient_text_01')}
              </p>
              <p className={[classes.blockRowLeftText, "font-17"].join(" ")}>
                {t('assets_efficient_text_02')}
              </p>
              <p className={[classes.blockRowLeftText, "font-17"].join(" ")}>
                {t('assets_efficient_text_03')}
              </p>
              <div className={classes.blockRowLeftBtn}>
                <Button>
                  {t('btn_start_trading')}
                </Button>
              </div>
            </div>
            <div className={classes.blockRowRight}>
              <div className={classes.blockRowRightImgs}>
                <div className={classes.blockRowRightImgsImg1}>
                  <img src={img1} alt="Efficient and safe trading" />
                </div>
                <div className={classes.blockRowRightImgsImg2}>
                  <img src={img2} alt="Efficient and safe trading" />
                </div>
                <div className={classes.blockRowRightImgsImg3}>
                  <img src={img3} alt="Efficient and safe trading" />
                </div>
                <div className={classes.blockRowRightImgsImg4}>
                  <img src={img4} alt="Efficient and safe trading" />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.imgMob}>
            <img src={imgMob} alt="Efficient and safe trading" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Efficient;

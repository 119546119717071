import HomePage from '../pages/HomePage/HomePage';
import TradingAssets from '../pages/TradingAssets/TradingAssets';
import TradingTools from '../pages/TradingTools/TradingTools';
import TradingView from '../pages/TradingView/TradingView';
import Academy from '../pages/Academy/Academy';
import LegalDocs from '../pages/LegalDocs/LegalDocs';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/assets', element: TradingAssets },
    { path: '/tools', element: TradingTools },
    { path: '/view', element: TradingView },
    { path: '/academy', element: Academy },
    { path: '/legal-documents', element: LegalDocs },
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/assets', element: TradingAssets },
    { path: '/:lang/tools', element: TradingTools },
    { path: '/:lang/view', element: TradingView },
    { path: '/:lang/academy', element: Academy },
    { path: '/:lang/legal-documents', element: LegalDocs },
];

export const headerRoutes = [
    { id: 1, path: 'assets', text: 'header_assets' },
    { id: 2, path: 'tools', text: 'header_tools' },
    { id: 3, path: 'view', text: 'header_view' },
    { id: 4, path: 'academy', text: 'header_academy' },
    { id: 5, path: 'legal-documents', text: 'header_legal_docs' },
];

import React, { useState } from "react";
import classes from "./Comprehensive.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Comprehensive = () => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([
    {
      id: 1,
      active: true,
      title: "tools_comprehensive_tab_01_title",
      text: "tools_comprehensive_tab_01_text",
    },
    {
      id: 2,
      active: false,
      title: "tools_comprehensive_tab_02_title",
      text: "tools_comprehensive_tab_02_text",
    },
    {
      id: 3,
      active: false,
      title: "tools_comprehensive_tab_03_title",
      text: "tools_comprehensive_tab_03_text",
    },
    {
      id: 4,
      active: false,
      title: "tools_comprehensive_tab_04_title",
      text: "tools_comprehensive_tab_04_text",
    },
    {
      id: 5,
      active: false,
      title: "tools_comprehensive_tab_05_title",
      text: "tools_comprehensive_tab_05_text",
    },
    {
      id: 6,
      active: false,
      title: "tools_comprehensive_tab_06_title",
      text: "tools_comprehensive_tab_06_text",
    },
  ]);
  return (
    <div className={[classes.comprehensive, "mt"].join(" ")}>
      <div className={classes.comprehensiveBody}>
        <div className="container">
          <h2 className={[classes.comprehensiveTitle, "font-35"].join(" ")}>
            {t('tools_comprehensive_title')}
          </h2>
          <div className={classes.comprehensiveRow}>
            <div className={classes.comprehensiveRowLeft}>
              {tabs.map((btn) => {
                return (
                  <p
                    key={btn.id}
                    onClick={() => {
                      setTabs(
                        tabs.map((subI) => {
                          if (btn.id === subI.id) {
                            return { ...subI, active: true };
                          } else {
                            return { ...subI, active: false };
                          }
                        })
                      );
                    }}
                    className={btn.active
                      ? [classes.comprehensiveRowLeftBtn, classes.active, "font-20"].join(" ")
                      : [classes.comprehensiveRowLeftBtn, "font-20"].join(" ")
                    }
                  >
                    {t(btn.title)}
                  </p>
                );
              })}
            </div>
            <div className={classes.comprehensiveRowRight}>
              {tabs.map((textI) => {
                return (
                  <p
                    key={textI.id}
                    className={textI.active
                      ? [classes.comprehensiveRowRightText, classes.active, "font-17"].join(" ")
                      : [classes.comprehensiveRowRightText, "font-17"].join(" ")
                    }
                  >
                    {t(textI.text)}
                  </p>
                );
              })}
              <div className={classes.comprehensiveRowRightBtn}>
                <Button>
                  {t('btn_start_trading')}
                </Button>
              </div>
            </div>
          </div>
          <div className={classes.comprehensiveMob}>
            {tabs.map((mobI) => {
              return (
                <div
                  className={classes.comprehensiveMobItem}
                  key={mobI.id}
                >
                  <h3 className={[classes.comprehensiveMobItemTitle, "font-17"].join(" ")}>
                    {t(mobI.title)}
                  </h3>
                  <p className={[classes.comprehensiveMobItemText, "font-17"].join(" ")}>
                    {t(mobI.text)}
                  </p>
                </div>
              );
            })}
            <div className={[classes.comprehensiveMobBtn, ""].join(" ")}>
              <Button>
                {t('btn_start_trading')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comprehensive;

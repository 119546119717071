import React from "react";
import classes from "./Exceptional.module.scss";
import { useTranslation } from "react-i18next";

const Exceptional = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.exceptional, "mt"].join(" ")}>
      <div className={[classes.exceptionalBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.exceptionalRow, ""].join(" ")}>
            <div className={[classes.exceptionalRowColumn1, ""].join(" ")}>
              <h4
                className={[classes.exceptionalRowColumn1Title, "font-35"].join(
                  " "
                )}
              >
                {t('academy_exceptional_title')}
              </h4>
              <p
                className={[classes.exceptionalRowColumn1Text, "font-17"].join(
                  " "
                )}
              >
                {t('academy_exceptional_text')}
              </p>
            </div>
            <div className={[classes.exceptionalRowColumn2, ""].join(" ")}>
              <h4
                className={[classes.exceptionalRowColumn2Title, "font-20"].join(
                  " "
                )}
              >
                {t('academy_exceptional_card_01_title')}
              </h4>
              <p
                className={[classes.exceptionalRowColumn2Text, "font-17"].join(
                  " "
                )}
              >
                {t('academy_exceptional_card_01_text')}
              </p>
            </div>
            <div className={[classes.exceptionalRowColumn3, ""].join(" ")}>
              <h4
                className={[classes.exceptionalRowColumn3Title, "font-20"].join(
                  " "
                )}
              >
                {t('academy_exceptional_card_02_title')}
              </h4>
              <p
                className={[classes.exceptionalRowColumn3Text, "font-17"].join(
                  " "
                )}
              >
                {t('academy_exceptional_card_02_text')}
              </p>
            </div>
            <div className={[classes.exceptionalRowColumn4, ""].join(" ")}>
              <h4
                className={[classes.exceptionalRowColumn4Title, "font-20"].join(
                  " "
                )}
              >
                {t('academy_exceptional_card_03_title')}
              </h4>
              <p
                className={[classes.exceptionalRowColumn4Text, "font-17"].join(
                  " "
                )}
              >
                {t('academy_exceptional_card_03_text')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exceptional;

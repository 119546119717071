import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Docs from "../../components/LegalDocsComponents/Docs/Docs";

const LegalDocs = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Legal Documents</title>
        <meta name="title" content="Expand your trading opportunities with iMarkets Trade" />
        <meta property="og:title" content="Expand your trading opportunities with iMarkets Trade" />
        <meta property="twitter:title" content="Expand your trading opportunities with iMarkets Trade" />

        <meta name="description" content="iMarkets Trade can help you expand your trading opportunities and achieve greater financial success. Join our platform and benefit from our extensive tools, diverse trading assets, and expert resources to improve your trading experience."/>
        <meta property="og:description" content="iMarkets Trade can help you expand your trading opportunities and achieve greater financial success. Join our platform and benefit from our extensive tools, diverse trading assets, and expert resources to improve your trading experience."/>
        <meta property="twitter:description" content="iMarkets Trade can help you expand your trading opportunities and achieve greater financial success. Join our platform and benefit from our extensive tools, diverse trading assets, and expert resources to improve your trading experience."/>

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Docs />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default LegalDocs;

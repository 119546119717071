import React from "react";
import classes from "./Crucial.module.scss";
import { useTranslation } from "react-i18next";

const Crucial = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.crucial, "mt"].join(" ")}>
      <div className={classes.crucialBody}>
        <div className="container">
          <div className={classes.crucialWrapper}>
            <h3 className={[classes.crucialTitle, "font-35"].join(" ")}>
              {t('tools_crucial_title')}
            </h3>
            <p className={[classes.crucialText, "font-17"].join(" ")}>
              {t('tools_crucial_text')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crucial;

import React, { useState } from "react";
import classes from './WhatTrade.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import circle from '../../../assets/img/HomePage/tools.png';

const tabs = [
    {
        title: 'home_what_trade_tab_01_title',
        text: [
            'home_what_trade_tab_01_text_01',
        ],
    },
    {
        title: 'home_what_trade_tab_02_title',
        text: [
            'home_what_trade_tab_02_text_01',
        ],
        list: [
            'S&P 500',
            'FTSE 100',
            'Nikkei 225',
            'DAX 30',
            'NDX 100',
        ],
    },
    {
        title: 'home_what_trade_tab_03_title',
        text: [
            'home_what_trade_tab_03_text_01',
        ],
    },
    {
        title: 'home_what_trade_tab_04_title',
        text: [
            'home_what_trade_tab_04_text_01',
        ],
    },
    {
        title: 'home_what_trade_tab_05_title',
        text: [
            'home_what_trade_tab_05_text_01',
        ],
    },
    {
        title: 'home_what_trade_tab_06_title',
        text: [
            'home_what_trade_tab_06_text_01',
        ],
        list: [
            'BTC/USD',
            'ETH/USD',
            'BTC/ETH',
            'BTC/USDT',
            'ETH/USDT',
        ],
    },
];

const WhatTrade = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.whatTrade}>
                <img className={classes.circle} src={circle} alt=''/>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t('home_what_trade_title')}
                </h2>
                <div className={classes.container}>
                    <div className={classes.buttons}>
                        {tabs.map((tab, index) =>
                            <div 
                                key={index} 
                                className={`${classes.button} ${index === currentTab && classes.buttonActive} no-select`}
                                onClick={() => setCurrentTab(index)}
                            >
                                <span>
                                    {t(tab.title)}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className={classes.content}>
                        <div className={[classes.text, 'font-17'].join(' ')}>
                            {tabs[currentTab].text.map((p, index) =>
                                <p key={index}>
                                    <Trans>
                                        {t(p)}
                                    </Trans>
                                </p>
                            )}
                        </div>
                        {tabs[currentTab]?.list &&
                            <ul className="font-17">
                                {tabs[currentTab].list.map((li, index) =>
                                    <li key={index}>
                                        {li}
                                    </li>
                                )}
                            </ul>
                        }
                        <div className={classes.btn}>
                            <Button href="tools">
                                {t('btn_learn_more')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhatTrade;

import React, { useEffect, useRef } from "react";
import classes from './ExBenefits.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/TradingView/ExBenefits/image_01.png';
import image_02 from '../../../assets/img/TradingView/ExBenefits/image_02.png';
import circle from '../../../assets/img/HomePage/tools_02.png';
import Button from '../../GlobalComponents/Button/Button';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const ExBenefits = () => {
    const { t } = useTranslation();
    const bodyRef_01 = useRef(null);
    const bodyRef_02 = useRef(null);
    const cardRef_01_01 = useRef(null);
    const cardRef_01_02 = useRef(null);
    const cardRef_01_03 = useRef(null);
    const cardRef_01_04 = useRef(null);
    const cardRef_02_01 = useRef(null);
    const cardRef_02_02 = useRef(null);
    const cardRef_02_03 = useRef(null);
    const cardRef_02_04 = useRef(null);
    const cards_01 = [
        {
            ref: cardRef_01_01,
            title: 'view_benefits_card_01_title',
            text: 'view_benefits_card_01_text',
        },
        {
            ref: cardRef_01_02,
            title: 'view_benefits_card_02_title',
            text: 'view_benefits_card_02_text',
        },
        {
            ref: cardRef_01_03,
            title: 'view_benefits_card_03_title',
            text: 'view_benefits_card_03_text',
        },
        {
            ref: cardRef_01_04,
            title: 'view_benefits_card_04_title',
            text: 'view_benefits_card_04_text',
        },
    ];
    const cards_02 = [
        {
            ref: cardRef_02_01,
            title: 'view_benefits_card_05_title',
            text: 'view_benefits_card_05_text',
        },
        {
            ref: cardRef_02_02,
            title: 'view_benefits_card_06_title',
            text: 'view_benefits_card_06_text',
        },
        {
            ref: cardRef_02_03,
            title: 'view_benefits_card_07_title',
            text: 'view_benefits_card_07_text',
        },
        {
            ref: cardRef_02_04,
            title: 'view_benefits_card_08_title',
            text: 'view_benefits_card_08_text',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.to(cardRef_01_01.current, {
                    y: 0,
                    delay: 0.2,
                    duration: 1,
                });
                gsap.to(cardRef_01_02.current, {
                    y: 0,
                    delay: 0.2,
                    duration: 1,
                });
                gsap.timeline().fromTo(cardRef_01_03.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef_01.current,
                        start: "top+=200px bottom",
                        end: "+=500px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_01_04.current, {
                    y: 250,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef_01.current,
                        start: "top+=200px bottom",
                        end: "+=500px",
                        scrub: 3,
                    }
                });

                gsap.timeline().fromTo(cardRef_02_01.current, {
                    y: 100,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef_02.current,
                        start: "top+=100px bottom",
                        end: "+=400px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_02_02.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef_02.current,
                        start: "top+=100px bottom",
                        end: "+=400px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_02_03.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef_02.current,
                        start: "top+=200px bottom",
                        end: "+=500px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_02_04.current, {
                    y: 250,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef_02.current,
                        start: "top+=200px bottom",
                        end: "+=500px",
                        scrub: 3,
                    }
                });
            }, 300);
        }
    }, []);
    return (
        <section className={classes.exBenefitsWrap}>
            <div className="container mt">
                <div className={classes.exBenefits}>
                    <img className={classes.circle} src={circle} alt=''/>
                    <div className={[classes.block, classes.block_01].join(' ')}>
                        <h2 className={[classes.title, 'font-35'].join(' ')}>
                            <Trans>
                                {t('view_benefits_title')}
                            </Trans>
                        </h2>
                        <div ref={bodyRef_01} className={classes.cards}>
                            {cards_01.map((card, index) =>
                                <div ref={card.ref} key={index} className={classes.cardWrap}>
                                    <div className={classes.card}>
                                        <h4 className={classes.cardTitle}>
                                            {t(card.title)}
                                        </h4>
                                        <p className={classes.cardText}>
                                            {t(card.text)}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={classes.btn}>
                            <Button white>
                                {t('btn_learn_more')}
                            </Button>
                        </div>
                        <img className={classes.image_01} src={image_01} alt=''/>
                    </div>
                    <div className={[classes.block, classes.block_02].join(' ')}>
                        <div ref={bodyRef_02} className={classes.cards}>
                            {cards_02.map((card, index) =>
                                <div ref={card.ref} key={index} className={classes.cardWrap}>
                                    <div className={classes.card}>
                                        <h4 className={classes.cardTitle}>
                                            {t(card.title)}
                                        </h4>
                                        <p className={classes.cardText}>
                                            {t(card.text)}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <img className={classes.image_02} src={image_02} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ExBenefits;

import React, { useEffect, useRef } from "react";
import classes from './QuickInfo.module.scss';
import man from '../../../assets/img/HomePage/QuickInfo/man.png';
import icons from '../../../assets/img/HomePage/QuickInfo/icons.png';
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const list = [
    'home_info_li_01',
    'home_info_li_02',
    'home_info_li_03',
    'home_info_li_04',
    'home_info_li_05',
    'home_info_li_06',
    'home_info_li_07',
    'home_info_li_08',
    'home_info_li_09',
];

const QuickInfo = () => {
    const { t } = useTranslation();
    const iconsRef = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(iconsRef.current, {
                y: -100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div ref={bodyRef} className={classes.quickInfo}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-35'].join(' ')}>
                        {t('home_info_title')}
                    </h2>
                    <ul className="font-17">
                        {list.map((li, index) =>
                            <li key={index}>
                                {t(li)}
                            </li>
                        )}
                    </ul>
                </div>
                <div className={classes.images}>
                    <img className={classes.man} src={man} alt='' />
                    <img ref={iconsRef} className={classes.icons} src={icons} alt='' />
                </div>
            </div>
        </section>
    );
};

export default QuickInfo;

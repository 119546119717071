import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/ToolsComponents/Banner/Banner";
import Comprehensive from "../../components/ToolsComponents/Comprehensive/Comprehensive";
import Quick from "../../components/ToolsComponents/Quick/Quick";
import Crucial from "../../components/ToolsComponents/Crucial/Crucial";

const TradingTools = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Trading Tools</title>
        <meta
          name="title"
          content="iMarkets Trade offers a wide range of trading tools to help users improve their trading experience and increase their chances of success."
        />
        <meta
          property="og:title"
          content="iMarkets Trade offers a wide range of trading tools to help users improve their trading experience and increase their chances of success."
        />
        <meta
          property="twitter:title"
          content="iMarkets Trade offers a wide range of trading tools to help users improve their trading experience and increase their chances of success."
        />

        <meta
          name="description"
          content="iMarkets Trade's comprehensive trading tools will turbocharge your trading with advanced charting, real-time market data, customizable strategies and social trading capabilities. Begin trading with iMarkets Trade today and take advantage of the capabilities of our powerful trading tools for your financial journey."
        />
        <meta
          property="og:description"
          content="iMarkets Trade's comprehensive trading tools will turbocharge your trading with advanced charting, real-time market data, customizable strategies and social trading capabilities. Begin trading with iMarkets Trade today and take advantage of the capabilities of our powerful trading tools for your financial journey."
        />
        <meta
          property="twitter:description"
          content="iMarkets Trade's comprehensive trading tools will turbocharge your trading with advanced charting, real-time market data, customizable strategies and social trading capabilities. Begin trading with iMarkets Trade today and take advantage of the capabilities of our powerful trading tools for your financial journey."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Comprehensive />
        <Quick />
        <Crucial />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default TradingTools;

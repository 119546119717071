import { useEffect, useRef } from "react";
import gsap from "gsap";
import classes from "./Support.module.scss";
import img1 from "../../../assets/img/Academy/4/1.png";
import { useTranslation } from "react-i18next";

const Support = () => {
  const { t } = useTranslation();
  const imageRef = useRef(null);
  const bodyRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(
        imageRef.current,
        {
          y: 200,
        },
        {
          y: 0,
          scrollTrigger: {
            trigger: bodyRef.current,
            end: "+=400px",
            scrub: 3,
          },
        }
      );
    }
  }, []);
  return (
    <div className={[classes.support, "mt"].join(" ")}>
      <div className={classes.supportBody} ref={bodyRef}>
        <div className="container">
          <div className={classes.supportRow}>
            <div className={classes.supportRowLeft} ref={imageRef}>
              <img src={img1} alt="Personalized Support:" />
            </div>
            <div className={classes.supportRowRight}>
              <h2 className={[classes.supportRowRightTitle, "font-35"].join(" ")}>
                {t('academy_support_title')}
              </h2>
              <div className={[classes.supportRowRightText, "font-17"].join(" ")}>
                {t('academy_support_text')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;

import React from "react";
import classes from "./Quick.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/Tools/2/arrow.png";
import { useTranslation } from "react-i18next";

const Quick = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.quick, "mt"].join(" ")}>
      <div className={classes.quickBody}>
        <div className="container">
          <h3 className={[classes.quickTitle, "font-35"].join(" ")}>
            {t('tools_info_title')}
          </h3>
          <div className={classes.quickRow}>
            <div className={classes.quickRowLeft}>
              <p className={[classes.quickRowLeftText, "font-17"].join(" ")}>
                {t('tools_info_text_01')}
              </p>
              <ul className={classes.quickRowLeftList}>
                <li className={[classes.quickRowLeftListItem, "font-17"].join(" ")}>
                  <img src={img1} alt="Quick Info about iMarkets Trade" />
                  {t('tools_info_li_01')}
                </li>
                <li className={[classes.quickRowLeftListItem, "font-17"].join(" ")}>
                  <img src={img1} alt="Quick Info about iMarkets Trade" />
                  {t('tools_info_li_02')}
                </li>
                <li className={[classes.quickRowLeftListItem, "font-17"].join(" ")}>
                  <img src={img1} alt="Quick Info about iMarkets Trade" />
                  {t('tools_info_li_03')}
                </li>
                <li className={[classes.quickRowLeftListItem, "font-17"].join(" ")}>
                  <img src={img1} alt="Quick Info about iMarkets Trade" />
                  {t('tools_info_li_04')}
                </li>
              </ul>
            </div>
            <div className={classes.quickRowRight}>
              <p className={[classes.quickRowRightText, "font-17"].join(" ")}>
                {t('tools_info_text_02')}
              </p>
              <p className={[classes.quickRowRightText, "font-17"].join(" ")}>
                {t('tools_info_text_03')}
              </p>
              <div className={classes.quickRowRightBtn}>
                <Button>
                  {t('btn_start_trading')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quick;

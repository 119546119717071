import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/TradingView/Banner/Banner";
import HowToConnect from "../../components/TradingView/HowToConnect/HowToConnect";
import ExBenefits from "../../components/TradingView/ExBenefits/ExBenefits";

const TradingView = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Trading View</title>
        <meta name="title" content="Enjoy trading on iMarkets Trade with TradingView integrating features" />
        <meta property="og:title" content="Enjoy trading on iMarkets Trade with TradingView integrating features" />
        <meta property="twitter:title" content="Enjoy trading on iMarkets Trade with TradingView integrating features" />

        <meta name="description" content="With the seamless integration of iMarkets Trade and TradingView, you can take your trading to the next level. Trade with confidence by utilizing advanced charting, real-time market data, and customizable strategies. "/>
        <meta property="og:description" content="With the seamless integration of iMarkets Trade and TradingView, you can take your trading to the next level. Trade with confidence by utilizing advanced charting, real-time market data, and customizable strategies. "/>
        <meta property="twitter:description" content="With the seamless integration of iMarkets Trade and TradingView, you can take your trading to the next level. Trade with confidence by utilizing advanced charting, real-time market data, and customizable strategies. "/>

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <ExBenefits />
        <HowToConnect />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default TradingView;

import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/TradingView/banner.png';
import { gsap } from "gsap";
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const imageRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(imageRef.current, {
                autoAlpha: 0,
                y: 150,
            }, {
                autoAlpha: 1,
                y: 0,
                delay: 0.5,
                duration: 1,
            });
        }
    }, []);
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-35'].join(' ')}>
                        {t('view_banner_title')}
                    </h2>
                    <div className={[classes.text, 'font-17'].join(' ')}>
                        <p>
                            {t('view_banner_text_01')}
                        </p>
                        <p>
                            {t('view_banner_text_02')}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn_start_trading')}
                        </Button>
                    </div>
                </div>
                <img ref={imageRef} className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default Banner;

import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/HomePage/Banner/Banner";
import UnderBanner from "../../components/HomePage/UnderBanner/UnderBanner";
import Benefits from "../../components/HomePage/Benefits/Benefits";
import WhatTrade from "../../components/HomePage/WhatTrade/WhatTrade";
import TradingAcademy from "../../components/HomePage/TradingAcademy/TradingAcademy";
import QuickInfo from "../../components/HomePage/QuickInfo/QuickInfo";
import Tools from "../../components/HomePage/Tools/Tools";
import Efficient from "../../components/HomePage/Efficient/Efficient";
import BecomeTrader from "../../components/HomePage/BecomeTrader/BecomeTrader";

const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>iMarkets Trade</title>
        <meta name="title" content="Expand your trading opportunities with iMarkets Trade" />
        <meta property="og:title" content="Expand your trading opportunities with iMarkets Trade" />
        <meta property="twitter:title" content="Expand your trading opportunities with iMarkets Trade" />

        <meta name="description" content="iMarkets Trade can help you expand your trading opportunities and achieve greater financial success. Join our platform and benefit from our extensive tools, diverse trading assets, and expert resources to improve your trading experience."/>
        <meta property="og:description" content="iMarkets Trade can help you expand your trading opportunities and achieve greater financial success. Join our platform and benefit from our extensive tools, diverse trading assets, and expert resources to improve your trading experience."/>
        <meta property="twitter:description" content="iMarkets Trade can help you expand your trading opportunities and achieve greater financial success. Join our platform and benefit from our extensive tools, diverse trading assets, and expert resources to improve your trading experience."/>

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <UnderBanner />
        <Benefits />
        <WhatTrade />
        <TradingAcademy />
        <QuickInfo />
        <Tools />
        <Efficient />
        <BecomeTrader />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
